<template lang="pug">
    .questionnaires-angiooteki(:class="{'questionnaires-angiooteki--re-contact': isReContact}")
        .questionnaires-angiooteki__row(
            v-for="(item, index) in questionnaires"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.questionnaires-angiooteki--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                    :disabled="additionOnly && item.old"
                )

                questionnaire-ae-qol(
                    v-model="item.AEQOl"
                    :error="getFieldError(item, index, 'AEQOl')"
                    :disabled="additionOnly && item.old && item.old_values.AEQOl"
                )

                questionnaire-aest(
                    v-model="item.AECT"
                    :error="getFieldError(item, index, 'AECT')"
                    :disabled="additionOnly && item.old && item.old_values.AECT"
                )

                questionnaire-aas(
                    v-model="item.AAS"
                    :error="getFieldError(item, index, 'AAS')"
                    :disabled="additionOnly && item.old && item.old_values.AAS"
                )

                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropQuestionnaires(index)"
                )
            hr.narrow(v-if="index < questionnaires.length - 1")
        .br
        app-button(
            plus
            @click="addNewQuestionnaires()"
        ) Добавить
</template>

<script>
import QuestionnaireAeQol from '@/components/pages/patients/questionnaires/modals/Ae-QoL';
import QuestionnaireAas from '@/components/pages/patients/questionnaires/modals/AAS';
import QuestionnaireAest from '@/components/pages/patients/questionnaires/modals/AEST';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
const requiredField = (value, item) => {
    return item.AEQOl || item.AECT || item.AAS;
};

export default {
    name: 'questionnaires-angiooteki',

    components: {
        QuestionnaireAeQol,
        QuestionnaireAas,
        QuestionnaireAest,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        questionnaires: [],
    }),

    validations: {
        questionnaires: {
            $each: {
                date: { required },
                AEQOl: { required: requiredField },
                AECT: {
                    required: requiredField,
                    possibleValue(value) {
                        return !value || +value >= 0;
                    },
                },
                AAS: { required: requiredField },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.questionnaires.map(e => e.date);
        },
    },

    watch: {
        questionnaires(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.questionnaires = this.value;
        });
    },

    methods: {
        addNewQuestionnaires(date = '') {
            this.questionnaires.push({
                id: uniqueId(),
                date: date,
                AEQOl: '',
                AECT: '',
                AAS: '',
            });
            this.$v.$reset();
        },

        dropQuestionnaires(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить опросник?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.questionnaires = this.questionnaires.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.questionnaires.$each.$iter[index].$dirty) {
                if (!this.$v.questionnaires.$each.$iter[index][field].required) {
                    return 'Заполните поле';
                }
                if (field === 'AECT') {
                    if (!this.$v.questionnaires.$each.$iter[index][field].possibleValue) {
                        return 'Неверное значение';
                    }
                }
            }
            return null;
        },

        getFieldsForSave() {
            return this.questionnaires.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                data: {
                    AEQOl: d.AEQOl,
                    AECT: d.AECT,
                    AAS: d.AAS,
                },
            }));
        },

        getFormattedSavedFields(questionnaires) {
            let fields = questionnaires.map(d => {
                const obj = {
                    id: d.id,
                    date: +moment(d.date_at),
                    AEQOl: d.data.AEQOl || '',
                    AECT: d.data.AECT || '',
                    AAS: d.data.AAS || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.questionnaires-angiooteki {
    .questionnaires-angiooteki--date {
        width: rem(168px);
    }

    &--re-contact {
        .questionnaires-angiooteki__row.old {
            display: none;
        }
    }

    @include mobile_or_P {
        .questionnaires-angiooteki--date {
            width: 100%;
        }
    }
}
</style>
