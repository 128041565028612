<template lang="pug">
    .questionnaire-aest
        app-text-field.result-field(
            v-model="result"
            label="AECT"
            is-number
            :max="16"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет AECT

            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name {{ id }}. {{ question.name }}
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") AECT = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-aest',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'За последние 3 месяца как часто у вас возникали ангиоотеки?',
                answers: [
                    { name: 'очень часто', value: 0 },
                    { name: 'часто', value: 1 },
                    { name: 'иногда', value: 2 },
                    { name: 'редко', value: 3 },
                    { name: 'совсем не возникали', value: 4 },
                ],
                value: null,
            },
            2: {
                name: 'За последние 3 месяца как сильно возникновение ангиоотеков повлияло на качество вашей жизни?',
                answers: [
                    { name: 'очень сильно', value: 0 },
                    { name: 'сильно', value: 1 },
                    { name: 'в некоторой степени', value: 2 },
                    { name: 'незначительно', value: 3 },
                    { name: 'совсем не повлияло', value: 4 },
                ],
                value: null,
            },
            3: {
                name: 'За последние 3 месяца насколько сильно непредсказуемость возникновения ангиоотека беспокоила вас?',
                answers: [
                    { name: 'очень сильно', value: 0 },
                    { name: 'сильно', value: 1 },
                    { name: 'в некоторой степени', value: 2 },
                    { name: 'незначительно', value: 3 },
                    { name: 'совсем не повлияло', value: 4 },
                ],
                value: null,
            },
            4: {
                name: 'За последние 3 месяца насколько хорошо вам удавалось контролировать возникновение ангиоотеков при помощи получаемой терапии?',
                answers: [
                    { name: 'совсем не удавалось', value: 0 },
                    { name: 'плохо', value: 1 },
                    { name: 'в некоторой степени', value: 2 },
                    { name: 'хорошо', value: 3 },
                    { name: 'очень хорошо', value: 4 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => sum + question.value, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-aest {
    .result-field {
        width: rem(72px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
